import React from 'react';
import Tilt from 'react-parallax-tilt';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="tilt-wrapper">
        <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} transitionSpeed={2000} scale={1.05} glareEnable={true} glareMaxOpacity={0.45} glareColor="#ffffff" glarePosition="all" glareBorderRadius="10px" glarePosition="bottom" glareReverse={true}>
          <div className="card">
            <div className="header"></div>
            <div className="content">
              <div className="logo" style={{ marginTop: -40, marginBottom: 40 }}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" width="100" />
              </div>
              <div className="title-holder">
                <h1>Get ready for 2024 change.</h1>
                <p>New website coming soon. Please check back to know more.</p>
              </div>
              <a href="https://link.cluzet.fr" title="Links" target="_blank" rel="noopener noreferrer">
                <div className="cta">Contact Joseph</div>
              </a>
            </div>
            <div className="footer">
              <span> <a className="underlined" href="https://github.com/jcluzet" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span>
            </div>
          </div>
        </Tilt>
      </div>
    );
  }
}

export default App;
